
import { Component, Vue } from "vue-property-decorator";
import LayoutDefault from "../../layouts/LayoutDefault.vue";
import MainBanner from "../../components/MainBanner.vue";
import TitleText from "../../components/TitleText.vue";
import PageBreak from "../../components/PageBreak.vue";
import CardShowcase from "../../components/CardShowcase.vue";

@Component<any>({
  metaInfo() {
      return {
        title: "Virtual Events - Leighton - Tech, Talent, Together",
        meta: [{
          vmid: 'description',
          name: 'description',
          content: `${ this.banner.text }`,
        }]
    }
  },
  components: {
    LayoutDefault,
    MainBanner,
    TitleText,
    PageBreak,
    CardShowcase
  }
})
export default class VirtualEvents extends Vue {
  private banner = {
    heading: "Implementing & delivering successful ",
    emphasis: "virtual events!",
    text:
      "Leighton are the only Workcast Delivery Partner to implement and deliver Workcast events in the UK.",
    action: {
      action: "/get-in-touch#contact",
      text: "Get in touch"
    },
    image: require("@/assets/banner-images/home.png")
  };

  private titleText1 = {
    customClass: "pt-5",
    subTitle: `
      Leighton are the only Workcast Delivery Partner to implement and deliver Workcast events in the UK. 
      <br>
      <br>
      WorkCast is a cloud-based, flexible, plugin & download-free SaaS based technology, Leighton can support all your online event needs, from webinars to managed or self-service webcasts to virtual events.
    `,
    decorator: [
      {
        image: require("@/assets/page-decorators/green-pill-inverted.png"),
        type: "green-pill d-none d-md-block",
        top: "120px",
        right: "1200px"
      }
    ]
  };

  private titleText2 = {
    customClass: "background--grey pt-5",
    heading: "About Our Service",
    subTitle:
      "...working in partnership with Workcast to deliver high quality virtual events"
  };

  private pageBreak = {
    customClass: "background--grey"
  };

  private titleText3 = {
    customClass: "background--grey pt-5",
    title: "Benefits of Working with Leighton for Virtual Events",
    subTitle: `
      <div class="row">
        <div class="col col-lg-5 col-sm-12 pt-lg-5">
          <ul>
            <li class="pb-4">Workcast requires no software install, plugins or Adobe Flash.</li>
            <li class="pb-4">Your brand is fully visible throughout your event</li>
            <li class="pb-4">View webcasts on any connected device, anywhere</li>
            <li class="pb-4">Presenters can present from any location globally </li>
            <li class="pb-4">Fully managed service ensures superior quality events</li>
            <li class="pb-4">Reduced costs by minimizing traditional event outgoings like venue hire</li>
            <li class="pb-4">Run Conferences, Careers Fairs, Trade Shows, Open Days and more</li>
          </ul>
        </div>
        <div class="col col-lg-5 offset-lg-2 col-sm-12 pt-lg-5">
          <ul>
            <li class="pb-4">Reduces the time required to run and prepare virtual events</li>
            <li class="pb-4">Focuses on achieving business goals and event objectives</li>
            <li class="pb-4">Reduces the anxiety and stress of running a live event</li>
            <li class="pb-4">High level of event engagement and positive attendee experience</li>
            <li class="pb-4">Detailed understanding of event metrics and focus on future improvements</li>
          </ul>
        </div>
      </div>
      `,
    decorator: [
      {
        image: require("@/assets/page-decorators/bubble3-medium.png"),
        type: "bubble-five d-none d-md-block",
        top: "-200px",
        left: "10px"
      }
    ]
  };

  private titleText4 = {
    customClass: "py-5",
    subTitle: `
      Interested in holding a Virtual Event?
      <br>
      Tech. Talent. Together.
    `,
    buttonText: "Get in touch",
    buttonLink: "/get-in-touch#contact",
    decorator: [
      {
        image: require("@/assets/page-decorators/leighton-pills-small.png"),
        type: "leighton-pills",
        top: "-120px",
        right: "1020px",
        height: "174px"
      },
      {
        image: require("@/assets/page-decorators/purple-pill.png"),
        type: "leighton-pills",
        top: "-250px",
        left: "75px",
        height: "500px"
      }
    ]
  };

  private cards = {
    cardIcons: true,
    squareTiles: true,
    customClass: "background--grey",
    showcaseTypeDesktop: "static",
    showcaseTypeMobile: "double-scroller",
    values: [
      {
        icon: require("@/assets/virtual-events-icons/card1.png"),
        text: "Browser-based platform to run Virtual Events"
      },
      {
        icon: require("@/assets/virtual-events-icons/card2.png"),
        text:
          "Fully brandable workflows including registration, booths and post event feedback"
      },
      {
        icon: require("@/assets/virtual-events-icons/card3.png"),
        text: "Virtual events including, live, simulive and on demand content"
      },
      {
        icon: require("@/assets/virtual-events-icons/card4.png"),
        text: "Fully branded sponsorship and exhibition spaces"
      },
      {
        icon: require("@/assets/virtual-events-icons/card5.png"),
        text:
          "Incorporate Polls, Surveys, Questionnaire, Q&A, Chat and Networking"
      },
      {
        icon: require("@/assets/virtual-events-icons/card6.png"),
        text:
          "Seamless integration with best in CRM/Marketing Automation solutions"
      },
      {
        icon: require("@/assets/virtual-events-icons/card7.png"),
        text: "Real time analytics and comprehensive reporting data"
      },
      {
        icon: require("@/assets/virtual-events-icons/card8.png"),
        text:
          "AWS cloud infrastructure and streaming protocols for fast reliable access"
      }
    ]
  };
}
