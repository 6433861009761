
import { Component, Prop, Vue } from "vue-property-decorator";
import CarouselList from "./common-ui/CarouselList.vue";
import TriptychIconsAdvanced from "./triptych-ui/IconsAdvanced.vue";
import TriptychIconsBasic from "./triptych-ui/IconsBasic.vue";
import TriptychTextAdvanced from "./triptych-ui/TextAdvanced.vue";
import TriptychTextBasic from "./triptych-ui/TextBasic.vue";
import { TriptychElement as ITriptych } from "@/interfaces/Triptych.interface";

@Component({
  components: {
    CarouselList,
    TriptychIconsAdvanced,
    TriptychIconsBasic,
    TriptychTextAdvanced,
    TriptychTextBasic
  }
})
export default class Triptych extends Vue {
  @Prop() private triptychElements!: ITriptych[];
  @Prop() private triptychCustomClass!: string;
  @Prop() private triptychType!: string;

  public activeItem = 1;

  updateActiveItem(currentItem: number) {
    this.activeItem = currentItem;
  }
}
