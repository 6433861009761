import { render, staticRenderFns } from "./IconsBasic.vue?vue&type=template&id=d558b200&scoped=true"
import script from "./IconsBasic.vue?vue&type=script&lang=ts"
export * from "./IconsBasic.vue?vue&type=script&lang=ts"
import style0 from "@/assets/css/triptych.scss?vue&type=style&index=0&id=d558b200&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d558b200",
  null
  
)

export default component.exports