
import { Component, Vue } from "vue-property-decorator";
import LayoutDefault from "../../layouts/LayoutDefault.vue";
import MainBanner from "../../components/MainBanner.vue";
import TitleText from "../../components/TitleText.vue";
import PageBreak from "../../components/PageBreak.vue";
import CardShowcase from "../../components/CardShowcase.vue";
import IconList from "../../components/IconList.vue";
import PathScroller from "@/components/PathScroller.vue";

@Component<any>({
  metaInfo() {
      return {
        title: "Team Augmentation - Leighton - Tech, Talent, Together",
        meta: [{
          vmid: 'description',
          name: 'description',
          content: `${ this.banner.text }`,
        }]
    }
  },
  components: {
    LayoutDefault,
    MainBanner,
    TitleText,
    PageBreak,
    CardShowcase,
    IconList,
    PathScroller
  }
})
export default class TeamAugmentation extends Vue {
  private banner = {
    heading: "We build high performing and successful",
    emphasis: "teams!",
    text:
      "We build high performing teams and develop successful software applications. Tech, talent, together.",
    action: {
      action: "/get-in-touch#contact",
      text: "Get in touch"
    },
    image: require("@/assets/banner-images/augmentation.png")
  };

  private titleText1 = {
    customClass: "mt-md",
    title:
      "IT and Digital Directors choose Leighton to supplement their existing teams for a variety of reasons:-"
  };

  private pathScroller = {
    customClass: "mt-md",
    item: [
      {
        icon: require("../../assets/team-augmentation-icons/recruitment.png"),
        alt: "Recruit icon",
        text: "They are struggling to recruit the right level of resource"
      },
      {
        icon: require("../../assets/team-augmentation-icons/delivery.png"),
        alt: "Fast delivery icon",
        text:
          "The scale of a project is far greater than their ability to deliver and they need support fast"
      },
      {
        icon: require("../../assets/team-augmentation-icons/flexibility.png"),
        alt: "Flexible partner icon",
        text:
          "There are peaks and troughs of IT demand and they need a flexible partner to support"
      },
      {
        icon: require("../../assets/team-augmentation-icons/upskill.png"),
        alt: "Upskill icon",
        text:
          "They want to upskill their own digital talent to better tackle today’s challenges, whilst upskilling the team to be more responsive and resilient in the future."
      }
    ]
  };

  private titleText2 = {
    customClass: "my-md",
    subTitle: "If this sounds like you…",
    buttonText: "Get in touch",
    buttonLink: "/get-in-touch#contact",
    decorator: [
      {
        image: require("@/assets/page-decorators/bubble5.png"),
        type: "bubble-five d-block d-md-none",
        bottom: "-100px",
        left: "-65px",
        height: "116px"
      }
    ]
  };

  private titleText3 = {
    customClass: "mt-md",
    subTitle: `
          Our team augmentation offering is a tried and trusted model which allows our customers to supplement their existing in-house teams with skilled people from Leighton.
          <br>
          <br>
          We build and supplement high performing teams with our own team members who possess vast expertise and experience across all of the disciplines of the software development lifecycle including Agile Delivery, Business Analysis, Solution Architecture, Software Engineering, Quality Assurance and Experience Design.
        `
  };

  private cards = {
    customClass: "mt-md pb-md",
    showcaseTypeDesktop: "static",
    showcaseTypeMobile: "static",
    icons: true,
    values: [
      {
        icon: require("@/assets/team-augmentation-icons/augmentation.svg"),
        title: "Team Augmentation",
        text: `
              <p>
                Our team members are embedded into your own in-house teams either sat alongside you, working remotely, or working from our own offices.
              </p>
              <p>
                Each colleague at Leighton has been through a rigorous recruitment and training process and we utilise our own software to <a class="font-weight-bold link emphasise--dark emphasise--dark--underline" aria-label="visit the human onboarding website" href="https://wearehuman.hr/" target="_blank">ensure they are onboarded into your team</a> and are productive quickly and efficiently. 
              </p>
            `
      },
      {
        icon: require("@/assets/team-augmentation-icons/trusted.svg"),
        title: "Trusted Partner",
        text: `
              <p>
                Leighton are chosen by IT leaders from some of the World’s largest brands to enable their own teams to deliver faster, consistently with increased quality.
              </p>
              <p>
                We are ISO 27001 certified and all of our consultants have been DBS checked.
              </p>
            `
      },
      {
        icon: require("@/assets/team-augmentation-icons/workflow.svg"),
        title: "Improved Workflow",
        text: `
              <p>
                Our technical consultants work within our customers IT and digital teams to support the delivery of complex projects. 
              </p>
              <p>
                We add pace, expertise and guidance to your team – enabling you to get better results more quickly.
              </p>
            `
      },
      {
        icon: require("@/assets/team-augmentation-icons/disciplines.svg"),
        title: "Multiple Disciplines",
        text: `
            <p>
              Leighton’s Agile Software flexible and cost-effective range of skills across the whole Software Development lifecycle. Our team includes all the experts you need to transform your business from Architects to UI/UX Designers.
            </p>
            <p>
              We have a huge variety of experience born out of delivering complex projects again and again and again.
            </p>
            `
      }
    ]
  };

  private titleText4 = {
    customClass: "background--grey pt-md",
    heading: "Team Augmentation",
    subTitle:
      "Our consultants cover a wide range of disciplines with a huge variety of experience built from delivery complex projects again and again and again.",
    decorator: [
      {
        image: require("@/assets/page-decorators/purple-pill-inverted.png"),
        type: "purple-pill",
        top: "-200px",
        right: "1200px",
        height: "700px"
      },
      {
        image: require("@/assets/page-decorators/bubble5.png"),
        type: "bubble-five d-none d-md-block",
        top: "600px",
        left: "60px",
        height: "209px"
      },
      {
        image: require("@/assets/page-decorators/green-pill.png"),
        type: "green-pill d-none d-md-block",
        top: "900px",
        left: "0"
      }
    ]
  };

  private IconList = {
    customClass: "background--grey py-md",
    listItems: [
      {
        icon: require("@/assets/team-augmentation-icons/architects.png"),
        title: "Solution Architects",
        textContent: `
              <p>
                Our experienced Architects work alongside our customers during the solution ideation, solution architecture and solution implementation stages. 
              </p>
              <p>
                We help you establish the complete business context for the solution, assessing the different options and then designing the architecture for the appropriate solution.  We will then guide the implementation of the solution to ensure it delivers the appropriate value.
              </p>
            `
      },
      {
        icon: require("@/assets/team-augmentation-icons/analyst.png"),
        title: "Business Analyst",
        textContent: `
            <p>
              Working alongside your team our Business Analysts document the requirements of your solution and create detailed user stories. 
            </p>
            <p>
              Working alongside your team we are well versed in recommending process improvements based on high quality analysis and research.
            </p>
              `
      },
      {
        icon: require("@/assets/team-augmentation-icons/engineers.png"),
        title: "Software Engineers",
        textContent: `
              <p>
                Our Software Engineers have years of experience delivering high profile complex projects in a multitude of different industries. Our experience and knowledge covers a wide range of programming languages with specific specialisms in Java, Javascript and Golang. We work across a wide variety of Frameworks including React JS, Node JS, Vue JS, Angular, Spring and Revel.
              </p>
              <p>
                As an AWS certified partner we are well versed in utilising Amazon Web Services to build solutions quickly and at scale for our customers.
              </p>
            `
      },
      {
        icon: require("@/assets/team-augmentation-icons/quality-engineers.png"),
        title: "Quality Engineers",
        textContent: `
              <p>
                Quality Assurance is paramount to any successful software project. So we have a team of highly experienced Quality Engineers who specialise in identifying and preventing bugs and defects in software solutions. 
              </p>
              <p>
                Our Engineers are trained in test design and execution from both a manual and automated test perspective with the aim of reducing risk and improving the quality and performance of your software solution.
              </p>
              <p>
                We can work alongside your own team of Quality Engineers or we can take full accountability for quality assurance for your solution.
              </p>
            `
      },
      {
        icon: require("@/assets/team-augmentation-icons/design.png"),
        title: "Experience Design",
        textContent: `
              <p>
                Our designers possess a raft of experiences across the disciplines of User Experience and User Interface design.
              </p>
              <p>
                We will look at the entire user journey, the steps that are taken, the actions and tasks that are required and we will make it better. We will then look at all the visual aspects of a user journey and make it look better, and be more accessible and inclusive.
              </p>
            `
      },
      {
        icon: require("@/assets/team-augmentation-icons/agile.png"),
        title: "Agile Delivery",
        textContent: `
              <p>
                Our Project Managers are masters of the discipline of Agile Software delivery and its many different guises. From Certified Scrum Masters to Experienced Prince2 Practitioners, we’ve got the lot. 
              </p>
              <p>
                We know that people and the way they interact with each other is the primary determinant factor of the success of a project and our Agile Delivery approach ensures that team members collaborate closely and learn from each other. The benefit for you. Your project is delivered quickly, in scope and your team have learnt an awful lot in the process.
              </p>
            `
      }
    ]
  };

  private titleText5 = {
    customClass: "my-md",
    subTitle: `
          Let’s build a high performing team together.
          <br>
          Tech. Talent. Together.
        `,
    buttonText: "Get in touch",
    buttonLink: "/get-in-touch#contact",
    decorator: [
      {
        image: require("@/assets/page-decorators/leighton-pills-small.png"),
        type: "leighton-pills",
        top: "-130px",
        right: "1000px",
        height: "174px"
      },
      {
        image: require("@/assets/page-decorators/bubble3-medium.png"),
        type: "bubble-three-medium",
        top: "-1300px",
        right: "1100px"
      }
    ]
  };
}
