
import { Component, Prop, Vue } from "vue-property-decorator";
import { TriptychElement } from "@/interfaces/Triptych.interface";

@Component({})
export default class TriptychIconsBasic extends Vue {
  @Prop() private tripIcons!: TriptychElement[];

  getImageAltText(t: string, i: number): string {
    const computedIndex = i + 1;
    if (t === undefined) {
      return "icon " + computedIndex;
    } else {
      return t + " icon";
    }
  }
}
