
import { Component, Prop, Vue } from "vue-property-decorator";
import { TriptychElement } from "@/interfaces/Triptych.interface";

@Component({})
export default class TriptychTextAdvanced extends Vue {
  @Prop() private tripText!: TriptychElement[];
  @Prop() private tripActive!: number;

  triptychScroll(direction: string): void {
    if (direction === "next") {
      if (this.tripActive >= this.tripText.length - 1) {
        this.$emit("update-active", 0);
      } else {
        this.$emit("update-active", this.tripActive + 1);
      }
    } else if (direction === "prev") {
      if (this.tripActive === 0) {
        this.$emit("update-active", 2);
      } else {
        this.$emit("update-active", this.tripActive - 1);
      }
    }
  }

  detectTabIndex(itemIndex: number): number {
    if (this.tripActive === itemIndex) {
      return 0;
    } else {
      return -1;
    }
  }

  detectHidden(itemIndex: number): string {
    if (this.tripActive === itemIndex) {
      return "false";
    } else {
      return "true";
    }
  }
}
