
import { Component, Vue } from "vue-property-decorator";
import LayoutDefault from "@/layouts/LayoutDefault.vue";
import MainBanner from "@/components/MainBanner.vue";
import TitleText from "@/components/TitleText.vue";
import Triptych from "@/components/Triptych.vue";
import CardShowcase from "@/components/CardShowcase.vue";
import BrandShowcase from "@/components/BrandShowcase.vue";

@Component<any>({
  metaInfo() {
      return {
        title: "Our Services - Leighton - Tech, Talent, Together",
        meta: [{
          vmid: 'description',
          name: 'description',
          content: `${ this.banner.text }`,
        }]
    }
  },
  components: {
    LayoutDefault,
    MainBanner,
    TitleText,
    Triptych,
    CardShowcase,
    BrandShowcase
  }
})
export default class Services extends Vue {
  private banner = {
    heading: "How do we help businesses",
    emphasis: "thrive?",
    text:
      "We can do amazing things with Tech. We supply technical Talent in the most in demand disciplines. We decide what’s best for you Together.",
    action: {
      text: "Learn More"
    },
    image: require("@/assets/banner-images/services.png")
  };

  private titleText1 = {
    customClass: "mt-md",
    title:
      "We are on a mission to help businesses and their customers thrive in todays fast paced world",
    subTitle: `
      Our focus is to help you achieve your goals faster. How? 
      <br>
      We decide that together. Whether we are designing new solutions, building new products or enhancing your own internal IT teams, we help you thrive by bringing people and technology together.
    `,
    decorator: [
      {
        image: require("@/assets/page-decorators/green-pill.png"),
        type: "green-pill d-none d-md-block",
        top: "350px",
        left: "150px"
      },
      {
        image: require("@/assets/page-decorators/green-pill.png"),
        type: "green-pill d-md-none",
        top: "725px",
        left: "-75px",
        height: "260px"
      }
    ]
  };

  private triptych = {
    customClass: "my-md",
    type: "advanced",
    element: [
      {
        title: "tech.",
        icon: require("@/assets/tech.png"),
        text: `
          <p>
          Tech that’s made for you. <span class="emphasise"><strong>It’s custom built. It’s what you need.</strong></span>
          </p>
          <p>
          How do we do it? We use AWS + Agile and DevOps principles.
          </p>
          <p>
          From <span class=emphasise><strong>making</strong></span> money, <span class=emphasise><strong>saving</strong></span> money, creating <span class=emphasise><strong>efficiencies</strong></span>,
          <br/><span class=emphasise><strong>integrating</strong></span> systems, <span class=emphasise><strong>solving problems</strong></span> we’ve got the tech for that.
          <br/>And anything else in between.
          </p>`
      },
      {
        title: "talent.",
        icon: require("@/assets/talent.png"),
        text: `
          <p>
            Talent that’s trusted. <span class=emphasise><strong>We’ve delivered big important projects.</strong></span> Again, and again and again.
          </p>
          <p>
            We’re your project management 101. Projects come and go. Bring us in when you need the expertise.
          </p>
          <p>
            We can sit with your internal teams or in our HQ. <span class=emphasise><strong>We’re good at both.</strong></span>
          </p>
          <p>
            We work with other suppliers. <span class=emphasise><strong>Onshore</strong></span> and <span class=emphasise><strong>offshore.</strong></span> Whatever the situation, <span class="emphasise"><strong>we can adapt.</strong></span>
          </p>
        `
      },
      {
        title: "together.",
        icon: require("@/assets/together.png"),
        text: `
          <p>
            Our <span class=emphasise><strong>process.</strong></span> We created it, so we can deliver against your goals. <span class=emphasise><strong>Your agenda is our agenda.</strong></span>
          </p>
          <p>
            It’s where our tech and talent offering come together. The thinkers and the doers.
          </p>
          <p>
            With you; <span class=emphasise><strong>together</strong></span> we customise the right blend of tech and talent. No two customers are the same. You get what you need.
          </p>
        `
      }
    ]
  };

  private titleText2 = {
    customClass: "background--grey pt-md",
    title: "About Our Services",
    subTitle: `
      Our focus is to help you achieve your goals faster. We embed highly skilled and experienced consultants into your delivery teams or take your bespoke ideas from inception to delivery.  Our consultants cover the whole software development lifecycle and our approach focuses on delivering scalable software and business results fast.<br><br>
      With nearly 30 years experience of working together with our customers to deliver successful software solutions, we believe in the value of empowering our customers. That’s why for any of our customer engagements, we work along side your teams, whether that’s co-developing or co-delivering. This ensures your teams are stronger and able to continue development in the future.
    `,
    decorator: [
      {
        image: require("@/assets/page-decorators/bubble5.png"),
        type: "bubble-five d-none d-md-block",
        top: "-125px",
        right: "1200px"
      },
      {
        image: require("@/assets/page-decorators/bubble5.png"),
        type: "bubble-five d-md-none",
        top: "-75px",
        right: "300px",
        height: "85px"
      }
    ]
  };

  private cards = {
    customClass: "background--grey py-md",
    showcaseTypeDesktop: "static",
    showcaseTypeMobile: "single-scroller",
    icons: true,
    squareTiles: false,
    values: [
      {
        icon: require("@/assets/services-icons/software-development.svg"),
        title: "Software Development",
        text:
          "From making money, saving money, creating efficiencies, integrating systems, solving problems we’ve got the tech for that. And anything else in between.",
        buttonText: "Find out more",
        buttonLink: "/services/software-development",
        ariaLabel: "Software Development"
      },
      {
        icon: require("@/assets/services-icons/team-augmentation.svg"),
        title: "Team Augmentation",
        text:
          "We build high performing teams and develop successful software applications.",
        buttonText: "Find out more",
        buttonLink: "/services/team-augmentation",
        ariaLabel: "Team Augmentation"
      }
    ]
  };

  private titleText3 = {
    customClass: "mt-md",
    title:
      "Experienced and Agile. Trusted by some of the world’s biggest brands.",
    subTitle:
      "We are chosen by IT and Business leaders from some of the world’s largest brands to enable their own teams to deliver faster, consistently with increased quality.",
    decorator: [
      {
        image: require("@/assets/page-decorators/leighton-pills-small.png"),
        type: "leighton-pills",
        top: "-85px",
        left: "50px",
        height: "174px"
      }
    ]
  };

  private brandShowcase = {
    customClass: "my-md",
    brandItems: [
      {
        name: "Atom Bank",
        logo: require("@/assets/brand-logos/atom.png")
      },
      {
        name: "Avios",
        logo: require("@/assets/brand-logos/avios.png")
      },
      {
        name: "Beyond Housing",
        logo: require("@/assets/brand-logos/beyond_housing.png")
      },
      {
        name: "British Airways",
        logo: require("@/assets/brand-logos/british-airways.png")
      },
      {
        name: "British Airways Holidays",
        logo: require("@/assets/brand-logos/british-airways-holidays.png")
      },
      {
        name: "Geoteric",
        logo: require("@/assets/brand-logos/geoteric.png")
      },
      {
        name: "Green Bean by NRG",
        logo: require("@/assets/brand-logos/green-bean.png")
      },
      {
        name: "HC One",
        logo: require("@/assets/brand-logos/hc-one.png")
      },
      {
        name: "IAG",
        logo: require("@/assets/brand-logos/iag.png")
      },
      {
        name: "Ikea",
        logo: require("@/assets/brand-logos/ikea.png")
      },
      {
        name: "MWW",
        logo: require("@/assets/brand-logos/mww.png")
      },
      {
        name: "Northumbrian Water",
        logo: require("@/assets/brand-logos/nwg.png")
      },
      {
        name: "Tharsus",
        logo: require("@/assets/brand-logos/tharsus.png")
      },
      {
        name: "Workcast",
        logo: require("@/assets/brand-logos/workcast.png")
      }
    ]
  };
}
