
import { Component, Prop, Vue } from "vue-property-decorator";

interface BrandShowcaseElement {
  name: string;
  logo: string;
}

@Component
export default class TitleText extends Vue {
  @Prop() private brandShowcaseCustomClass!: string;
  @Prop() private brandShowcaseItems!: BrandShowcaseElement;
}
