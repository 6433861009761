
import { Component, Prop, Vue } from "vue-property-decorator";
import { TriptychElement } from "@/interfaces/Triptych.interface";

@Component({})
export default class TriptychIconsAdvanced extends Vue {
  @Prop() private tripIcons!: TriptychElement[];
  @Prop() private tripActive!: number;

  keymonitor(event: KeyboardEvent, i: number): void {
    const keys = {
      left: 37,
      right: 39,
      end: 35,
      home: 36
    };

    if (event.keyCode === keys.left) {
      event.preventDefault();
      if (i === 0) {
        this.TabFocus("triptych__tab--2");
      } else {
        i = i - 1;
        this.TabFocus("triptych__tab--" + i);
      }
    } else if (event.keyCode === keys.right) {
      event.preventDefault();
      if (i === 2) {
        this.TabFocus("triptych__tab--0");
      } else {
        i = i + 1;
        this.TabFocus("triptych__tab--" + i);
      }
    } else if (event.keyCode === keys.home) {
      event.preventDefault();
      this.TabFocus("triptych__tab--0");
    } else if (event.keyCode === keys.end) {
      event.preventDefault();
      this.TabFocus("triptych__tab--2");
    }
  }

  TabFocus(tab: string): void {
    const focusTab = document.getElementById(tab);
    focusTab?.focus();
  }

  setActiveItem(currentItem: number): void {
    this.$emit("update-active", currentItem);
  }

  detectSelected(itemIndex: number): boolean {
    if (this.tripActive === itemIndex) {
      return true;
    } else {
      return false;
    }
  }

  detectTabIndex(itemIndex: number): number {
    if (this.tripActive === itemIndex) {
      return 0;
    } else {
      return -1;
    }
  }

  getImageAltText(t: string, i: number): string {
    const computedIndex = i + 1;
    if (t === undefined) {
      return "icon " + computedIndex;
    } else {
      return t + " icon";
    }
  }
}
