
import { Component, Vue } from "vue-property-decorator";
import LayoutDefault from "../../layouts/LayoutDefault.vue";
import MainBanner from "../../components/MainBanner.vue";
import TitleText from "../../components/TitleText.vue";
import PathScroller from "../../components/PathScroller.vue";
import CardShowcase from "../../components/CardShowcase.vue";
import IconList from "../../components/IconList.vue";
import BrandShowcase from "../../components/BrandShowcase.vue";

@Component<any>({
  metaInfo() {
      return {
        title: "Software Development - Leighton - Tech, Talent, Together",
        meta: [{
          vmid: 'description',
          name: 'description',
          content: `${ this.banner.text }`,
        }]
    }
  },
  components: {
    LayoutDefault,
    MainBanner,
    TitleText,
    PathScroller,
    CardShowcase,
    IconList,
    BrandShowcase
  }
})
export default class SoftwareDevelopment extends Vue {
  private banner = {
    heading: "We can build bespoke software solutions",
    emphasis: "quickly!",
    text:
      "From making money, saving money, creating efficiencies, integrating systems, solving problems we’ve got the tech for that. And anything else in between.",
    action: {
      action: "/get-in-touch#contact",
      text: "Get in touch"
    },
    image: require("@/assets/banner-images/software-development.png")
  };

  private titleText1 = {
    customClass: "mt-md",
    subTitle: `
    With nearly 30 years of software development experience we know that to build a successful technology solution requires the right process.
    <br>
    <br>
    Using a bespoke blend of our tech and talent, together with our unique methodology we work in partnership with you to co-create exceptional, innovative, successful solutions to solve big problems.
    <br>
    <br>
    <strong>
    Our Approach delivers from the very start…
    </strong>
    `
  };

  private pathScroller = {
    customClass: "mt-md",
    item: [
      {
        icon: require("@/assets/pathscroller-images/path-1.png"),
        alt: "Quality Seal icon",
        text: "Quality guaranteed"
      },
      {
        icon: require("@/assets/pathscroller-images/path-2.png"),
        alt: "Magnifying glass icon",
        text: "Full commercial and project transparency"
      },
      {
        icon: require("@/assets/pathscroller-images/path-3.png"),
        alt: "Checklist icon",
        text: "Fully accessible and compliant solutions"
      },
      {
        icon: require("@/assets/pathscroller-images/path-4.png"),
        alt: "Double arrows icon",
        text: "Minimal business change - we work with your business"
      },
      {
        icon: require("@/assets/pathscroller-images/path-5.png"),
        alt: "Flowchart Icon",
        text: "An agile and flexible approach to project delivery"
      },
      {
        icon: require("@/assets/pathscroller-images/path-6.png"),
        alt: "Delivery icon",
        text: "Pace!"
      },
      {
        icon: require("@/assets/pathscroller-images/path-7.png"),
        alt: "Handshake icon",
        text: "Co-creation at every stage"
      }
    ]
  };

  private titleText2 = {
    customClass: "mt-md",
    buttonText: "Get in touch",
    buttonLink: "/get-in-touch#contact",
    decorator: [
      {
        image: require("@/assets/page-decorators/green-pill.png"),
        type: "green-pill",
        top: "110px",
        left: "110px"
      }
    ]
  };

  private titleText3 = {
    customClass: "background--grey mt-md pt-md",
    heading: `The Path to Success...`,
    subTitle: `...working in partnership to co-create at every stage`,
    decorator: [
      {
        image: require("@/assets/page-decorators/green-pill.png"),
        type: "green-pill d-md-none",
        top: "-100px",
        left: "-40px",
        height: "320px"
      }
    ]
  };

  private pathScroller1 = {
    v2: true,
    customClass: "background--grey pt-md",
    item: [
      {
        icon: require("@/assets/pathscroller-images/about-us/newpath1.png"),
        alt: "Quality Seal icon",
        text: `
          <span class="emphasise--dark">Agreeing the Strategy</span>
          <br>
          Understanding your vision and goals and aligning on what success looks like
        `,
        textTop: true
      },
      {
        icon: require("@/assets/pathscroller-images/about-us/newpath2.png"),
        alt: "Magnigying glass icon",
        text: `
          <span class="emphasise--dark">Designing the Solution</span>
          <br>
          3 steps to success. Fully exploring and understanding you and your needs, defining clear scope and solution outcomes & designing exceptional and innovative solutions
        `
      },
      {
        icon: require("@/assets/pathscroller-images/about-us/newpath3.png"),
        alt: "Checklist icon",
        text: `
          <span class="emphasise--dark">Delivering the solution</span>
          <br>
          With pace and experitse, using the best technology and proven DevOps methodology
        `,
        textTop: true
      },
      {
        icon: require("@/assets/pathscroller-images/about-us/newpath4.png"),
        alt: "Double arrows icon",
        text: `
          <span class="emphasise--dark">Optimizing success</span>
          <br>
          Ongoing support and expertise to continually optimize business results
        `
      }
    ],
    itemMobile: [
      {
        icon: require("@/assets/pathscroller-images/about-us/newpath1-mobile.png"),
        alt: "Quality Seal icon",
        text: `
          <span class="emphasise--dark">Agreeing the Strategy</span>
          <br>
          Understanding your vision and goals and aligning on what success looks like
        `,
        textTop: true
      },
      {
        icon: require("@/assets/pathscroller-images/about-us/newpath2-mobile.png"),
        alt: "Magnigying glass icon",
        text: `
          <span class="emphasise--dark">Designing the Solution</span>
          <br>
          3 steps to success. Fully exploring and understanding you and your needs, defining clear scope and solution outcomes & designing exceptional and innovative solutions
        `
      },
      {
        icon: require("@/assets/pathscroller-images/about-us/newpath3-mobile.png"),
        alt: "Checklist icon",
        text: `
          <span class="emphasise--dark">Delivering the solution</span>
          <br>
          With pace and experitse, using the best technology and proven DevOps methodology
        `,
        textTop: true
      },
      {
        icon: require("@/assets/pathscroller-images/about-us/newpath4-mobile.png"),
        alt: "Double arrows icon",
        text: `
          <span class="emphasise--dark">Optimizing success</span>
          <br>
          Ongoing support and expertise to continually optimize business results
        `
      }
    ]
  };

  private titleText4 = {
    customClass: "background--grey",
    decorator: [
      {
        image: require("@/assets/page-decorators/bubble5.png"),
        type: "bubble-five",
        top: "250px",
        right: "1110px"
      },
      {
        image: require("@/assets/page-decorators/leighton-pills.png"),
        type: "leighton-pills",
        top: "750px",
        left: "50px"
      }
    ]
  };

  private IconList = {
    customClass: "background--grey py-md",
    listItems: [
      {
        icon: require("@/assets/software-development-icons/step-1.svg"),
        title: "Step 01 - Strategy",
        textContent:
          "This is all about understanding the context of the project and being clear on your vision, goals and the criteria for success"
      },
      {
        icon: require("@/assets/software-development-icons/step-2.svg"),
        title: "Step 02 - Discovery",
        textContent:
          "At this stage we are exploring your needs. We build up the business requirements, understand the constraints, engage with the key stakeholders and build insight on the project."
      },
      {
        icon: require("@/assets/software-development-icons/step-3.svg"),
        title: "Step 03 - Define",
        textContent:
          "We now begin to define the solution, coming up with ideas and evaluating them, building user personas and creating user stories."
      },
      {
        icon: require("@/assets/software-development-icons/step-4.svg"),
        title: "Step 04 - Design",
        textContent:
          "Only when we are clear on what the solution needs to do and who it needs to do it for do we begin designing how it will work. From architecture through to wireframes and prototypes we work up the entire solution design."
      },
      {
        icon: require("@/assets/software-development-icons/step-5.svg"),
        title: "Step 05 - Deliver",
        textContent:
          "At this stage we start to develop the software solution. Our agile approach means you see results fast and regularly and our focus on quality assurance means that the end solution meets the requirements that have been set our in previous steps."
      },
      {
        icon: require("@/assets/software-development-icons/step-6.svg"),
        title: "Step 06 - Optimise",
        textContent:
          "Whilst the solution will be live, we will partner with you to evaluate what has been achieved and explore how we can optimise and enhance the experience and the results."
      }
    ]
  };

  private titleText5 = {
    customClass: "mt-md",
    subTitle: `
      28 years and counting. Experienced and agile.
      <br>
      Trusted by some of the world’s biggest brands.
    `,
    decorator: [
      {
        image: require("@/assets/page-decorators/yellow-pill.png"),
        type: "yellow-pill d-none d-md-block",
        top: "-300px",
        right: "1200px"
      },
      {
        image: require("@/assets/page-decorators/yellow-pill.png"),
        type: "yellow-pill d-md-none",
        top: "-200px",
        right: "325px",
        height: "350px"
      },
      {
        image: require("@/assets/page-decorators/bubble3-small.png"),
        type: "bubble-one d-none d-lg-block",
        top: "-300px",
        right: "-50px"
      }
    ]
  };

  private brandShowcase = {
    customClass: "my-md",
    brandItems: [
      {
        name: "Atom Bank",
        logo: require("@/assets/brand-logos/atom.png")
      },
      {
        name: "Avios",
        logo: require("@/assets/brand-logos/avios.png")
      },
      {
        name: "Beyond Housing",
        logo: require("@/assets/brand-logos/beyond_housing.png")
      },
      {
        name: "British Airways",
        logo: require("@/assets/brand-logos/british-airways.png")
      },
      {
        name: "British Airways Holidays",
        logo: require("@/assets/brand-logos/british-airways-holidays.png")
      },
      {
        name: "Geoteric",
        logo: require("@/assets/brand-logos/geoteric.png")
      },
      {
        name: "Green Bean by NRG",
        logo: require("@/assets/brand-logos/green-bean.png")
      },
      {
        name: "HC One",
        logo: require("@/assets/brand-logos/hc-one.png")
      },
      {
        name: "IAG",
        logo: require("@/assets/brand-logos/iag.png")
      },
      {
        name: "Ikea",
        logo: require("@/assets/brand-logos/ikea.png")
      },
      {
        name: "MWW",
        logo: require("@/assets/brand-logos/mww.png")
      },
      {
        name: "Northumbrian Water",
        logo: require("@/assets/brand-logos/nwg.png")
      },
      {
        name: "Tharsus",
        logo: require("@/assets/brand-logos/tharsus.png")
      },
      {
        name: "Workcast",
        logo: require("@/assets/brand-logos/workcast.png")
      }
    ]
  };
}
